var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('pop-dialog', _vm._g(_vm._b({
    attrs: {
      "height": "auto",
      "width": _vm.isMobile ? '90vw' : '700px'
    }
  }, 'pop-dialog', _vm.$attrs, false), _vm.$listeners), [_c('div', {
    staticClass: "pop-main"
  }, [_c('div', {
    staticClass: "pop-title"
  }, [_vm._v(_vm._s(_vm.$lang("Task")))]), _c('div', {
    staticClass: "taskbox"
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("状态")]), _c('div', {
    staticClass: "input"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.condition_type,
      expression: "modifyData.condition_type"
    }],
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.modifyData, "condition_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "task"
    }
  }, [_vm._v("扩展任务")]), _c('option', {
    attrs: {
      "value": "invitation_count"
    }
  }, [_vm._v("邀请用户数")]), _c('option', {
    attrs: {
      "value": "mining_earnings"
    }
  }, [_vm._v("每时挖矿收益")])])])]), ['invitation_count', 'mining_earnings'].includes(_vm.modifyData.condition_type) ? _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("标题")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.title,
      expression: "modifyData.title"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.modifyData.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "title", $event.target.value);
      }
    }
  })])]) : _vm._e(), ['invitation_count', 'mining_earnings'].includes(_vm.modifyData.condition_type) ? _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("图标")]), _c('div', {
    staticClass: "input file-upload"
  }, [_vm.modifyData.icon ? _c('img', {
    attrs: {
      "src": _vm.modifyData.icon,
      "height": "40px",
      "width": "40px"
    }
  }) : _vm._e(), !_vm.modifyData.icon ? _c('svg-icon', {
    attrs: {
      "name": "upload-filled"
    }
  }) : _vm._e(), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChange($event, 'fileInput', 'icon');
      }
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("条件值")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.condition_value,
      expression: "modifyData.condition_value"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.modifyData.condition_value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "condition_value", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-tip"
  }, [_vm.modifyData.condition_type == 'task' ? [_vm._v("输入扩展任务ID")] : _vm._e(), _vm.modifyData.condition_type == 'invitation_count' ? [_vm._v("输入邀请用户数值")] : _vm._e(), _vm.modifyData.condition_type == 'mining_earnings' ? [_vm._v("输入每时挖矿收益数值")] : _vm._e()], 2), _c('div', {
    staticClass: "form-item"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("提示语")]), _c('div', {
    staticClass: "input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.modifyData.tip,
      expression: "modifyData.tip"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.modifyData.tip
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.modifyData, "tip", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "btn",
    style: {
      background: _vm.themeMainColor(_vm.theme).main
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(_vm._s(_vm.$lang("Submit")))])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };